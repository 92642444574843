// copied from mappers: https://github.expedia.biz/Brand-Expedia/uis-prime-mappers/blob/master/src/mappers/common/events.js#L1
/* eslint-disable */
const eventDefinitions = {
    "AppFederatedSearch": "event2",
    "ProductView": "event3",
    "ProductViewUDP": "event4",
    "AppETPEligible": "event5",
    "Leads": "event6",
    "LeadUnits": "event7",
    "LeadValue": "event8",
    "InfositeHotelPostBook": "event9",
    "DatedSearch": "event10",
    "NonDatedSearch": "event11",
    "SearchResultsProduct": "event12",
    "SearchResultsDisambig": "event13",
    "InventoryNoAvailable": "event14",
    "SearchResultsAirportDropoff": "event15",
    "SearchResultsAirport": "event16",
    "AppInAppPushNotification": "event17",
    "InventoryPartialAvailable": "event18",
    "CouponAddSuccess": "event21",
    "CouponAddFailure": "event22",
    "CouponRemoved": "event23",
    "UserAccountCreated": "event25",
    "UserAccountLogin": "event26",
    "UserAccountUpdates": "event27",
    "UserAccountAutoCreated": "event28",
    "UserAccountLogout": "event29",
    "PageDiscover": "event31",
    "PageViewfinder": "event33",
    "AppBookByPhone": "event34",
    "CallSupport": "event35",
    "CheckoutStart": "event36",
    "AppUserFeedback": "event37",
    "CheckoutError": "event38",
    "CheckoutErrorFormFieldInvalid": "event39",
    "AppOpt-OutforPush": "event40",
    "AppOpt-InforPush": "event41",
    "AppOpt-InforMarketing": "event42",
    "AppOpt-OutforMarketing": "event43",
    "TypeaheadSelectionDepth": "event44",
    "TypeaheadSearch": "event45",
    "TypeaheadCharactersTyped": "event46",
    "SearchResultsLXGT": "event47",
    "SocialShare": "event48",
    "UserGeneratedContent": "event49",
    "SearchResultsFlightDetailsExpand": "event50",
    "SearchResultsHotel": "event51",
    "SearchResultsCar": "event52",
    "SearchResultsPackage": "event53",
    "SearchResultsFlight": "event54",
    "SearchResultsCruise": "event55",
    "SearchResultsLocalExpert": "event56",
    "AirAttachImpressions": "event57",
    "AirAttachClicks": "event58",
    "SearchResultsCarDrop-off": "event59",
    "RewardsPointRedemption": "event60",
    "RewardsRegistration": "event61",
    "PriceChange": "event62",
    "ItinView": "event63",
    "ItinCancellation": "event64",
    "ItinChange": "event65",
    "ClicktoChat": "event66",
    "RewardsAutoRegistration": "event67",
    "CheckoutStart-Rail": "event68",
    "CheckoutStart-MICKO": "event69",
    "CheckoutStart-Hotel": "event70",
    "CheckoutStart-Flight": "event71",
    "CheckoutStart-Package": "event72",
    "CheckoutStart-Car": "event73",
    "CheckoutStart-Cruise": "event74",
    "CheckoutStart-LocalExpert": "event75",
    "CruiseDepositPayment": "event76",
    "CruiseDepositFinalPayment": "event77",
    "CheckoutStartBaggageEligible": "event78",
    "FlightBaggageEligiblePurchase": "event79",
    "ScratchPadNotificationOpt-In": "event80",
    "ScratchPadNotificationOpt-Out": "event81",
    "EmailOpen": "event82",
    "KrazyGlueClick": "event83",
    "AlertSignup": "event84",
    "AlertEmailValidation": "event85",
    "KrazyGlueImpression": "event86",
    "TripExpertImpression": "event87",
    "TripExpertClick": "event88",
    "CarsMulti-Sort": "event89",
    "CheckoutStart-Insurance": "event90",
    "RecommenderLXClick": "event92",
    "TravelArrangerAdded": "event93",
    "TravelArrangerConfirmed": "event94",
    "ItinClicktoFlightCheckIn": "event95",
    "ItinFlightCheckInSuccess": "event96",
    "ItinFlightCheckInFailure": "event97",
    "ItinItineraryErrors": "event98",
    "TransactionStatusSuccess": "event99",
    "BotvilleTraffic": "event101",
    "InfositeHotelReviewTranslatorCharacters": "event102",
    "AdobeVisitorAPIEnabled": "event103",
    "AdobeVisitorAPITimedOut": "event104",
    "MRPHotelSearchResults": "event105",
    "MRPHotelInfositeView": "event106",
    "MRPHotelRateisCheapest": "event107",
    "MRPHotelCheckoutStart": "event108",
    "MRPHotelPurchaseConfirmation": "event109",
    "CheckoutSubmitCar": "event110",
    "CheckoutSubmitLX": "event111",
    "CheckoutSubmitGT": "event112",
    "CheckoutSubmitCruise": "event113",
    "PwPExpedia+Eligible": "event114",
    "PwPCiti/PartnerEligible": "event115",
    "PwPMultiplePrograms": "event116",
    "PwPExpedia+PointsBurned": "event117",
    "PwPShopwithPointsEnabled": "event118",
    "PwPPaywithOrbucksEligible": "event119",
    "ChromePluginInstall": "event120",
    "ChromePluginSearch": "event121",
    "ProductViewInsurance": "event122",
    "PwPOrbucksUsed": "event123",
    "SearchResultsRail": "event124",
    "PwPAMEXEligible": "event125",
    "PwP<3500ExpediaThreshold": "event126",
    "FlightsBookedTicketPending": "event127",
    "SearchResultsHotelAFRPropertyDisplayed": "event128",
    "InfositeHotelAFRPropertyDisplayed": "event129",
    "SearchResultsHotelCompressionScore": "event130",
    "PackageSearchResultsCount": "event131",
    "MIPOffersPresent": "event132",
    "CreditCardEntered": "event133",
    "CheckoutSubmitHotel": "event134",
    "CheckoutAFRPropertyStart": "event135",
    "CheckoutAFRPropertyPurchased": "event136",
    "SearchWizardType": "event137",
    "CheckoutSubmitFlight": "event138",
    "SoftAccountModal": "event139",
    "EmailSubscriptionUpdate": "event140",
    "EmailUnsubscribe": "event141",
    "AdBlockerEnabled": "event142",
    "VoiceRecognition": "event143",
    "OpenSearchSelectionDepth": "event144",
    "OpenSearch": "event145",
    "OpenSearchCharactersTyped": "event146",
    "OpenSearchClickDepth": "event147",
    "ShortlistSaved": "event148",
    "ShortlistUnsaved": "event149",
    "QualtricsSurveySubmissions": "event150",
    "FlexSLPPage": "event151",
    "MIPEligibleHotel": "event152",
    "MIPEligibleCar": "event153",
    "MIPEligibleLX": "event154",
    "EstimatedGP": "event155",
    "BaseBiddingGP": "event156",
    "BiddingGP": "event157",
    "MIPBannerClickthrough": "event158",
    "MIPEligibleGT": "event159",
    "HotelVacationRentals": "event160",
    "Hotel3PIInventory": "event161",
    "Hotel3PIisCheapest": "event162",
    "Hotel3PIisIncremental": "event163",
    "HotelVRTnLEvent": "event164",
    "SearchResultsFlightPinnedOfferImpression": "event165",
    "HotelVRroom#equalsSearchroom#": "event166",
    "HotelVRsimilarroomsmessage": "event167",
    "HotelVRDamageDeposit": "event168",
    "CheckoutConfirmationMICKO": "event169",
    "PercentReviewImpression": "event170",
    "PercentReviewsRT-Out": "event171",
    "PercentReviewsRT-In": "event172",
    "RouteHappyScoreTracked": "event173",
    "RouteHappy/RealTimeReviewRatio": "event174",
    "RealTimeReviewsScoreTracked": "event175",
    "RealTimeReviews": "event176",
    "UGCPagination": "event177",
    "UGCAutomaticReviewPresent": "event178",
    "CheckoutStartItinHotelUpgrade": "event179",
    "CheckoutStartFlight+Car": "event180",
    "CheckoutStartFlight+Hotel+Car": "event181",
    "CheckoutStartHotel+Car": "event182",
    "CheckoutStartLX-GT": "event183",
    "ItinUpgradeOfferImpression": "event185",
    "ItinXsellUber": "event186",
    "CheckoutConfirmationLXGT": "event187",
    "CheckoutConfirmationRail": "event188",
    "CheckoutConfirmationLX": "event189",
    "ItinInsuranceSAProduct": "event190",
    "CheckoutConfirmationFlight": "event191",
    "CheckoutConfirmationFH": "event192",
    "CheckoutConfirmationFC": "event193",
    "CheckoutConfirmationFHC": "event194",
    "CheckoutConfirmationHC": "event195",
    "CheckoutConfirmationHotel": "event196",
    "CheckoutConfirmationCar": "event197",
    "AdobeVisitorID1stPartyCookie": "event198",
    "UISOrders": "event199",
    "AWSExpweb": "event200",
    "AWSCloudPages": "event201",
    "AWSProcessedBookings": "event202",
    "FlightSubPubDiscountShownFSR": "event203",
    "FlightSubPubDiscountShownUDP": "event204",
    "StorefrontmWeb": "event205",
    "StorefrontExpweb": "event206",
    "StorefrontProjectMercury": "event207",
    "AppNotificationReceipt": "event208",
    "AppAlexaIntents": "event209",
    "AppiOS3DTouch": "event211",
    "AppNotificationEntry": "event212",
    "AppUserFavorite": "event214",
    "AppUserUnfavorite": "event215",
    "AppGoogleAutoLogIn": "event216",
    "AppiOSWebCredentialsLogin": "event217",
    "AppGoogleSmartLockSignIn": "event218",
    "AppFacebookLogin": "event219",
    "PageUsableTimeLogged": "event220",
    "PageUsableTime": "event221",
    "ScrollDepthSinglePageCKO": "event222",
    "TimeBeforeAnalyticsLogged": "event223",
    "TimeBeforeAnalytics": "event224",
    "CheckoutSinglePage": "event225",
    "MODOfferPresent": "event226",
    "MarketingClick": "event227",
    "FlexHomepage": "event228",
    "UDPFlightPaidSeatToggle": "event230",
    "AppNon-ImageBytesDownloaded": "event231",
    "AppNon-ImageBytesUploaded": "event232",
    "AppImageBytesDownloaded": "event233",
    "AppImageBytesUploaded": "event234",
    "AppTimetoClickLogged": "event235",
    "AppTimetoClick": "event236",
    "AppAPICallTimeLogged": "event237",
    "AppAPICallTime": "event238",
    "MERSignUp": "event239",
    "HotelRefundable": "event240",
    "HotelNonRefundable": "event241",
    "HotelDomestic": "event242",
    "HotelInternational": "event243",
    "AppStorefrontSearchFieldsChanged": "event244",
    "AppSearchResultsFlightScrollDepth": "event245",
    "FlexAMPPages": "event246",
    "StorefrontAjaxStart": "event247",
    "StorefrontAjaxComplete": "event248",
    "StorefrontAjaxTiming": "event249",
    "ItinActiveHotel": "event250",
    "ItinActiveFlight": "event251",
    "ItinActiveCar": "event252",
    "ItinActiveLX": "event253",
    "ItinActiveRail": "event254",
    "ItinActivePackageFH": "event255",
    "ItinActiveCruise": "event256",
    "ItinActiveGT": "event257",
    "CommonOfferPresent": "event258",
    "AttachEligibleUser": "event259",
    "CGDReviewsImpression": "event260",
    "ChangeFlightSummaryProductView": "event261",
    "ChangeFlightReviewChangebutton": "event262",
    "ChangeFlightReviewCallbackbutton": "event263",
    "SearchResultsHotelCacheHit": "event264",
    "SearchResultsHotelCacheMiss": "event265",
    "InsuranceGP": "event266",
    "AppBannerImpression": "event267",
    "AppBannerClick": "event268",
    "HandleCount": "event269",
    "CallCount": "event270",
    "HandleDuration": "event271",
    "AppFSRClickedListposition": "event272",
    "AppFSFZeroresults": "event273",
    "APPUDPFFUpgrade": "event274",
    "APPUDPFFChange": "event275",
    "APPMobCache": "event276",
    "APPOWCachedNon-Bookable": "event277",
    "AppBannerImpression-Footer": "event278",
    "UDPMARScount": "event279",
    "UDPAverageHotelXSellPrice": "event280",
    "UDPAverageHotelXSellStarRating": "event281",
    "AppHSRPinnedResultPresent": "event282",
    "AppHSRPinnedResultSoldOut": "event283",
    "UDPRecommenderServicecount": "event284",
    "UDPMDIAPIcount": "event285",
    "AppTripsCallMade": "event286",
    "AppTripsCallSuccess": "event287",
    "AppTripsCallFailure": "event288",
    "SavingsGuaranteeBannerImpression": "event289",
    "SavingsGuaranteeBannerClick": "event290",
    "SavingsGuaranteeCKOPayment": "event291",
    "SavingsGuaranteePointsBurned": "event292",
    "AppSuccessfulVoiceSearch": "event293",
    "AppPartialVoiceSearch": "event294",
    "AppVoiceSearchClickDepth": "event295",
    "InfositeHotelRoomsandRates-Impression": "event296",
    "InfositeHotelRoomsandRates-Click": "event297",
    "AppSearchResultsHotelScrollDepth": "event298",
    "SearchResultsLX-GTNone": "event299",
    "RFRRID10%Sample": "event300",
    "ShoppingCartAddHotel": "event301",
    "ShoppingCartAddCar": "event302",
    "ShoppingCartAddFlight": "event303",
    "ShoppingCartAddLX": "event304",
    "ShoppingCartAddLX-GT": "event305",
    "ShoppingCartAddInsurance": "event306",
    "ShoppingCartAddCruise": "event307",
    "ShoppingCartAddRail": "event308",
    "ShoppingCartAdd3PP": "event309",
    "ShoppingCartRemoveHotel": "event311",
    "ShoppingCartRemoveCar": "event312",
    "ShoppingCartRemoveFlight": "event313",
    "ShoppingCartRemoveLX": "event314",
    "ShoppingCartRemoveLX-GT": "event315",
    "ShoppingCartRemoveInsurance": "event316",
    "ShoppingCartRemoveCruise": "event317",
    "ShoppingCartRemoveRail": "event318",
    "ShoppingCartRemove3PP": "event319",
    "AppMarketingDeepLinkOpen": "event320",
    "AppLSModuleWizard": "event321",
    "AppLSModuleActiveItinerary": "event322",
    "AppLSModuleAirAttach": "event323",
    "AppLSModuleMemberPricing": "event324",
    "AppLSModuleMyLists": "event325",
    "AppLSModuleHotelsNearby-Android": "event326",
    "AppLSModuleAccountSignin/Creation": "event327",
    "AppLSModuleGlobalNavBar": "event328",
    "AppLSModuleLastMinuteDeals": "event329",
    "AppHolidayPromotion-Impression": "event330",
    "AppHolidayPromotion-Click": "event331",
    "AppKochavaInstall": "event332",
    "AppGreedycallsmade": "event333",
    "AppGreedycallsterminated": "event334",
    "AppStorefrontFHAppUpdate": "event335",
    "KrazyGlueMLOBImpression": "event343",
    "KrazyGlueFallbackImpression": "event344",
    "KrazyGluePrimingImpression": "event345",
    "ListsEmptySavedList": "event346",
    "ListsNumberofSavedItems": "event347",
    "ListsEmptyRecentSearches": "event348",
    "ListsNumberOfRecentSearches": "event349",
    "AppSearchResultsLXScroll": "event352",
    "AppInfositeLXDateChange": "event353",
    "TimedifferenceUserteam": "event354",
    "InfositeHotelLXOffers": "event355",
    "ShortlistSaveAttempt": "event356",
    "Purchase": "purchase",
    "PriceChangeTotalReferenceDollarDifference": "event364",
    "PriceChangeTotalDollarDifference": "event365",
    "HotelFeeL26Breakout": "event370",
    "HotelFeeProperty": "event371",
    "HotelFeeResort": "event372",
    "HotelFeePerPersonPerNight": "event373",
    "HotelFeeGroupedMandatory": "event374",
    "HotelFeeOther": "event375",
    "PriceChangeFltReferenceDollarDifference": "event376",
    "PriceChangeFltBaseFareDifference": "event377",
    "PriceChangeFltBaseFareChangePercent": "event378",
    "PriceChangeFltTaxDifference": "event379",
    "PriceChangeFltTaxChangePercent": "event380",
    "PriceChangeFltFeeDifference": "event381",
    "PriceChangeFltFeeChangePercent": "event382",
    "SearchPinnedHotelViewer": "event383",
    "PriceChangeFltDollarDifference": "event385",
    "PriceChangeHotDollarDifference": "event386",
    "PriceChangeCarDollarDifference": "event387",
    "SearchResultLXHasReviews": "event390",
    "SearchResultsLXNoReviews": "event391",
    "PriceChangeHotBaseFareDifference": "event392",
    "PriceChangeHotBaseFareChangePercent": "event393",
    "PriceChangeHotTaxDifference": "event394",
    "PriceChangeHotTaxChangePercent": "event395",
    "PriceChangeHotFeeDifference": "event396",
    "PriceChangeHotFeeChangePercent": "event397",
    "PriceChangeHotReferenceDollarDifference": "event399",
    "SLPaffHeroImageFallback": "event406",
    "HotelNumberOfSoldOut": "event407",
    "PriceChangeLXDollarDifference": "event408",
    "CheaperAlternativeDates": "event409",
    "HotelNumberofUnpriced": "event417",
    "HotelNumberPerPage": "event418",
    "LXVolumeBasedPricing": "event422",
    "HotelLargePartySearch": "event437",
    "NoCCEnabledHotelSearch": "event440",
    "SLPaffFallBackImageUsed": "event426",
    "SLPaffNumFallBackImages": "event428",
    "SLPaffNumTotalImages": "event429",
    "LX3PIInventory": "event430",
    "HotelLargePartySearchSymmetric": "event439",
    "NoCCEnabledHotelInfositeView": "event441",
    "NoCCEnabledHotelCheckoutStart": "event442",
    "NoCCEnabledHotelPurchaseConfirmation": "event443",
    "HotelMultiSAProperty": "event447",
    "GPSSearchDestinationInteract": "event445",
    "GPSSearchDestinationScrolled": "event446",
    "CheckoutStartMultipleSAProperty": "event448",
    "SearchResultsThirdPartyPackage": "event455",
    "CheckoutStart-PWA": "event456",
    "CheckoutConfirmationMultipleSAProperty": "event458",
    "CheckoutConfirmationFlightSingleSplitTicket": "event465",
    "NewVisit": "event463",
    "EntryPageView": "event464",
    "CheckoutConfirmationCruise": "event466",
    "CheckoutAdditionalInfoLX3PI": "event470",
    "ListsNumberOfCustomItems": "event477",
    "InsuranceNoProductOffered": "event487",
    "InsuranceNoModuleShown": "event488",
    "InsuranceProductLocaleQuebec": "event489",
    "KrazyGlueMLOBEmbeddedImpression": "event483",
    "EmailValidationError": "event491",
    "EmailValidationWarning": "event492",
    "PriceChangeTotalSavingsDollarDifference": "event494",
    "SearchResults3PPackageCount": "event495",
    "SearchResults3PPackageHotelsCount": "event496",
    "SearchResults3PPackageSoldOutHotelsCount": "event497",
    "SearchResults3PPackageHotelsMatchingTotalCount": "event498",      
    "SearchResults3PPackageHotelsMatchingFirstPageCount": "event499",
    "SearchResults3PPackageSoldOutHotelsMatchingFirstPageCount": "event500",
    "SearchResults3PPackageMissingExpediaHotelIdCount": "event501",
    "InfositeHotel3PPackageMatchedProductId": "event502",
    "InfositeHotel3PPackageMatchedOffers": "event503",
    "InfositeHotel3PPackageMatchedExtendedDates": "event504",
    "InfositeHotel3PPackageAirportTransfer": "event505", 
    "InfositeHotel3PPackageAllInclusive": "event506",
    "InfositeHotel3PPackageDirectFlight": "event507",
    "InfositeHotel3PPackageTotalPriceDelta": "event508",
    "CheckoutSubmitFlight+Hotel": "event509",
    "CheckoutSubmitFlight+Hotel+Car": "event510",
    "CheckoutSubmitFlight+Car": "event511",
    "CheckoutSubmitHotel+Car": "event512",
    "CheckoutFormFieldStart": "event513",
    "CheckoutFormFieldPrePopulated": "event514",
    "FlightBrandedFarePresent": "event515",
    "CheckoutSubmitMultipleSAProperty": "event519",
    "CheckoutSubmitMRPHotel": "event520",
    "CheckoutStartAsymmetricProperty": "event521",
    "CheckoutSubmitAsymmetricProperty": "event522",
    "CheckoutConfirmationAsymmetricProperty": "event523",
    "CheckoutStartHotel+Hotel": "event524",
    "CheckoutSubmitHotel+Hotel": "event525",
    "CheckoutConfirmationHotel+Hotel": "event526",
    "PackageTotalPriceShown": "event531",
    "PackageBundledSavingsShown": "event532",
    "PackageFlightDiscountAmount": "event533",
    "TripsOverviewViewed": "event550",
    "TripsModuleRecentlyViewedHotel": "event551",
    "TripsModuleRecommendedActivities": "event552",
    "TripsModuleSeeAllHotel": "event553",
    "TripsModuleSeeAllFlight": "event554",
    "TripsModuleSeeAllActivities": "event555",
    "TripsModuleSeeAllCars": "event556"
};

const eventsByName = eventDefinitions;
const eventsByEventCode = [];

Object.keys(eventsByName).map((key) => {
    const value = eventsByName[key];
    eventsByEventCode[value] = key;
});

module.exports = {
    eventsByName,
    eventsByEventCode,
};
