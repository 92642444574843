/* eslint-disable class-methods-use-this, consistent-return, getter-return */
const PlatformAnalytics = require('./platform-analytics');
const { eventsByEventCode } = require('./utilities/events');

class TealiumProxy {
    getUtag() {
        notSupportedFunction('dctk.tealium.getUtag');
    }
}

class DctkCompatibility {
    constructor() {
        this.tealiumProxy = new TealiumProxy();
        this.isCompatibilityLayer = true;
    }

    get logging() {
        if (safelyGetPlatformAnalyticsInstance()) {
            return safelyGetPlatformAnalyticsInstance().getClientLog();
        }
    }

    get loggingAdapter() {
        return this.logging;
    }

    get tealium() {
        return this.tealiumProxy;
    }

    get enabled() {
        return true;
    }

    onReady(callback) {
        if (safelyGetPlatformAnalyticsInstance()) {
            callback();
        }
    }

    trackEvent(params, name, trackVars, trackEvents) {
        try {
            if (safelyGetPlatformAnalyticsInstance()) {
                params = mangleTheLegacyOverriddenTrackEventParameters(params, name, trackVars, trackEvents);

                if (params.trackVars && Object.keys(params.trackVars).length > 0 && !params.microMessages) {
                    const error = new Error(`Add params.microMessages in addition to params.trackVars ${params.trackVars}`);
                    safelyGetPlatformAnalyticsInstance().getClientLog().logError(error);
                    return false;
                }

                if (!params.rfrr) {
                    safelyGetPlatformAnalyticsInstance().getClientLog().logError(new Error('params.rfrr is required'));
                    return false;
                }

                if (!params.name) {
                    params.name = params.rfrr;
                }

                if (!params.type) {
                    params.type = 'o';
                }

                if (params.isUserBased != null && !params.isUserBased) {
                    params.name = `ape:${params.name}`;
                }

                let eventType;
                switch (params.type) {
                case 'o':
                    eventType = 'click';
                    break;
                case 'e':
                    eventType = 'exit';
                    break;
                case 'i':
                    eventType = 'impression';
                    break;
                default:
                    eventType = null;
                }

                let microMessages = [];
                microMessages.push({
                    schemaName: 'referrer',
                    messageContent: {
                        referrerId: params.rfrr,
                        linkName: params.name,
                        eventType,
                    },
                });

                if (params.microMessages) {
                    microMessages = microMessages.concat(params.microMessages);
                }

                if (params.trackEvents) {
                    microMessages = microMessages.concat(turnEventsIntoMicroMessages(params.trackEvents));
                }

                return safelyGetPlatformAnalyticsInstance().trackEvent(microMessages);
            }
        } catch (err) {
            safelyGetPlatformAnalyticsInstance().getClientLog().logError(`Got this Error while publishing track event using dctk-compatibility layer ${err}`);
            return false;
        }
    }

    trackAction(rfrr, element, trackVars, trackEvents) {
        return this.trackEvent({
            name: 'RFRR Action Link',
            rfrr,
            trackVars,
            trackEvents,
        });
    }

    trackNextPage(rfrr, element, trackVars, trackEvents) {
        return this.trackEvent({
            name: 'RFRR Next Page Link',
            rfrr,
            trackVars,
            trackEvents,
        });
    }

    trackExit(rfrr, element, trackVars, trackEvents) {
        return this.trackEvent({
            type: 'e',
            name: 'RFRR Exit Link',
            rfrr,
            trackVars,
            trackEvents,
        });
    }

    trackImpression(rfrr, trackVars, trackEvents) {
        return this.trackEvent({
            type: 'i',
            isUserBased: false,
            rfrr,
            trackVars,
            trackEvents,
        });
    }

    evaluateExperiment(params) {
        sendExperimentRequest('/api/bucketing/v1/evaluateExperiments', params);
    }

    evaluateExperimentAndLog(params) {
        sendExperimentRequest('/api/bucketing/v1/evaluateExperimentsAndLog', params);
    }

    logExperiments(params) {
        this.evaluateExperimentAndLog(params);
    }

    logExperiment(params) {
        this.evaluateExperimentAndLog(params);
    }

    addExperiments() {
        notSupportedFunction('dctk.addExperiments');
    }

    track() {
        notSupportedFunction('dctk.track');
    }

    get omtr() {
        notSupportedFunction('dctk.omtr');
        return {};
    }

    initOmniture() {
        notSupportedFunction('dctk.initOmniture');
    }

    setupOmniture() {
        notSupportedFunction('dctk.setupOmniture');
    }

    clearOmnitureObj() {
        notSupportedFunction('dctk.clearOmnitureObj');
    }

    // eslint-disable-next-line camelcase
    s_exp_trackClick() {
        notSupportedFunction('dctk.s_exp_trackClick');
    }

    trackHotelPos() {
        notSupportedFunction('dctk.trackHotelPos');
    }

    get experimentsExposed() {
        notSupportedFunction('dctk.experimentsExposed');
        return '';
    }

    verifyExposedExperiments() {
        notSupportedFunction('dctk.verifyExposedExperiments');
    }
}

function notSupportedFunction(name) {
    if (safelyGetPlatformAnalyticsInstance()) {
        const err = new Error(`${name} not supported by DCTK compatibility layer`);
        safelyGetPlatformAnalyticsInstance().getClientLog().logError(err);
    }
}

function safelyGetPlatformAnalyticsInstance() {
    const instance = PlatformAnalytics.getInstance();
    if (!instance) {
        const err = new Error('Race condition: PlatformAnalytics instance has not yet been created');
        console.error(err);
    }
    return instance;
}

function mangleTheLegacyOverriddenTrackEventParameters(params, name, trackVars, trackEvents) {
    if (typeof params === 'string') {
        // This supports when the first argument used to be the event type
        const type = params;
        params = { type };
    }

    if (name) {
        params.name = name;
    }

    if (trackVars) {
        params.trackVars = trackVars;
    }

    if (trackEvents) {
        params.trackEvents = trackEvents;
    }

    if (!params.rfrr && params.trackVars && params.trackVars.prop16 && params.trackVars.eVar28
        && params.trackVars.prop16 === params.trackVars.eVar28) {
        // This is ugly, sorry. Summary is that prop16 and eVar28 are what referrer.referrerId (rfrr) gets mapped to
        // https://ewegithub.sb.karmalab.net/EWE/uis-prime-mappers/blob/master/src/mappers/to-adobe-analytics/platform/referrer.js
        // so this nastiness is for loads of legacy usages that continue to get copied/pasted all over the shop.
        // Just see this search for an idea of this bundle of joy (yes - that's 100 pages of results, excluding
        // ExpWeb): https://ewegithub.sb.karmalab.net/search?utf8=%E2%9C%93&q=org%3AEWE+eVar28+prop16&type=Code
        params.rfrr = params.trackVars.prop16;
        delete params.trackVars.prop16;
        delete params.trackVars.eVar28;
    }

    return params;
}

function turnEventsIntoMicroMessages(events) {
    const regexToMatchEventAndOptionalEquals = /([^,=]+)=?([^,]*)/g;
    const microMessages = [];
    let match;

    // eslint-disable-next-line no-cond-assign
    while (match = regexToMatchEventAndOptionalEquals.exec(events)) {
        const messageContent = { name: mapEventName(match[1]) };
        if (match[2]) {
            const val = parseFloat(match[2]);
            if (!Number.isNaN(val)) {
                messageContent.value = val;
            }
        }
        microMessages.push({
            schemaName: 'events',
            messageContent,
        });
    }

    return microMessages;
}

function mapEventName(eventName) {
    if (eventsByEventCode[eventName]) {
        return eventsByEventCode[eventName];
    }
    return eventName;
}

function sendExperimentRequest(url, params) {
    let req;
    if (params.xmlHttpRequest) {
        req = params.xmlHttpRequest;
    } else {
        req = new XMLHttpRequest();
    }

    if (params.callback) {
        req.onload = () => {
            if (req.status >= 200 && req.status < 300) {
                params.callback(makeAwesomeExperimentResponse(JSON.parse(req.responseText)));
            } else {
                params.callback(makeSuperExperimentErrorResponse('Non-2xx response'));
            }
        };

        req.onerror = () => params.callback(makeSuperExperimentErrorResponse('Connection error'));
        req.ontimeout = () => params.callback(makeSuperExperimentErrorResponse('Timeout'));
    }

    req.open('POST', url);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(turnExperimentParamsIntoPostData(params));
}

function turnExperimentParamsIntoPostData(params) {
    const data = {
        verbose: true,
    };

    if (params.ids) {
        data.id = params.ids;
    } else if (params.id) {
        data.id = params.id instanceof Array ? params.id : [params.id];
    }

    if (params.name) {
        if (params.name instanceof Array) {
            data.name = params.name;
        } else {
            data.name = [params.name];
        }
    }

    if (params.guid) {
        data.guid = params.guid;
    } else {
        data.guid = getDeviceUserAgentId();
    }

    if (params.tpid) {
        data.tpid = params.tpid;
    } else {
        data.tpid = getTpid();
    }

    if (params.eapid) {
        data.eapid = params.eapid;
    } else {
        data.eapid = getEapid();
    }

    if (params.pageName) {
        data.pageName = params.pageName;
    } else {
        data.pageName = getPageName();
    }

    const abov = getAbov();
    if (abov !== '') {
        data.overrides = abov;
    }

    return JSON.stringify(data);
}

function getTpid() {
    return Number(grabSomethingFromCookiesOrError(/tpid=v\.1,([^;]+)/));
}

function getEapid() {
    return Number(grabSomethingFromCookiesOrError(/iEAPID=([^,^;]+)/));
}

function getDeviceUserAgentId() {
    return grabSomethingFromCookiesOrError(/DUAID=([^;]+)/);
}

function getPageName() {
    if (safelyGetPlatformAnalyticsInstance()) {
        return safelyGetPlatformAnalyticsInstance()._ctx.pageName;
    }
}

function getAbov() {
    if (document && document.cookie) {
        const match = /abov=([^;]+)/.exec(decodeURIComponent(document.cookie));
        if (match) {
            return match[1];
        }
    }
    return '';
}

function grabSomethingFromCookiesOrError(regex) {
    try {
        if (document && document.cookie != null) {
            const match = regex.exec(decodeURIComponent(document.cookie));
            if (match) {
                return match[1];
            }
            if (safelyGetPlatformAnalyticsInstance()) {
                safelyGetPlatformAnalyticsInstance().getClientLog().logError(new Error(`${regex} not found in cookies`));
            }
            return '';
        }
    } catch (err) {
        safelyGetPlatformAnalyticsInstance().getClientLog().logError(new Error(`Error parsing cookie based on ${regex}`), err);
        return '';
    }
}

function makeAwesomeExperimentResponse(response) {
    const data = {
        id: undefined,
        value: 0,
        experiments: [],
    };

    if (response.message) {
        data.error = turnErrorIntoSomethingUseful(response.message);
    }

    if (response.evaluatedExperiments) {
        data.experiments = response.evaluatedExperiments;

        if (response.evaluatedExperiments.length > 0) {
            const firstExperiment = response.evaluatedExperiments[0];
            if (firstExperiment.message) {
                data.error = turnErrorIntoSomethingUseful(firstExperiment.message);
            }
            data.id = firstExperiment.id;
            data.value = firstExperiment.value;
        }
    }

    return data;
}

function turnErrorIntoSomethingUseful(error) {
    let errorMsg = error.id;
    if (error.description) {
        errorMsg += ` (${error.description})`;
    }
    return new Error(errorMsg);
}

function makeSuperExperimentErrorResponse(error) {
    return {
        error,
        value: 0,
    };
}

module.exports = new DctkCompatibility();
